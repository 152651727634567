<template>
    <div class=container>
        <div id="everything">
    <div id="header">
    <div class="left"><h1><router-link to="/repository/PubFig">PubFig: Public Figures Face Database</router-link></h1></div><br>
    <div class="right">
    <h2><router-link to="/repository/PubFig/explore" class="unselected">Explore</router-link></h2>
    <h2><router-link to="/repository/PubFig/download" class="unselected">Download</router-link></h2>
    <h2><router-link to="/repository/PubFig/results" class="unselected">Results</router-link></h2>
    </div>
</div>

<div id="explore">
<div class="exploretable" id="devpeople">
    <h2>Development Set (60 People)</h2>
    <div class="exploreperson">
        <a href="Abhishek_Bachan.jpg" title="Click to see a thumbnail all images of Abhishek Bachan"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Abhishek_Bachan_thumb.jpg"></a>
        <div class="info">
            <h4>Abhishek Bachan</h4>
            107 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="Alex_Rodriguez.jpg" title="Click to see a thumbnail all images of Alex Rodriguez"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Alex_Rodriguez_thumb.jpg"></a>
        <div class="info">
            <h4>Alex Rodriguez</h4>
            166 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="Ali_Landry.jpg" title="Click to see a thumbnail all images of Ali Landry"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Ali_Landry_thumb.jpg"></a>
        <div class="info">
            <h4>Ali Landry</h4>
            155 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="Alyssa_Milano.jpg" title="Click to see a thumbnail all images of Alyssa Milano"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Alyssa_Milano_thumb.jpg"></a>
        <div class="info">
            <h4>Alyssa Milano</h4>
            330 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Anderson_Cooper.jpg" title="Click to see a thumbnail all images of Anderson Cooper"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Anderson_Cooper_thumb.jpg"></a>
        <div class="info">
            <h4>Anderson Cooper</h4>
            209 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Anna_Paquin.jpg" title="Click to see a thumbnail all images of Anna Paquin"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Anna_Paquin_thumb.jpg"></a>
        <div class="info">
            <h4>Anna Paquin</h4>
            145 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/https://cave.cs.columbia.edu/old/databases/pubfig/explore/Audrey_Tautou.jpg" title="Click to see a thumbnail all images of Audrey Tautou"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Audrey_Tautou_thumb.jpg"></a>
        <div class="info">
            <h4>Audrey Tautou</h4>
            161 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Barack_Obama.jpg" title="Click to see a thumbnail all images of Barack Obama"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Barack_Obama_thumb.jpg"></a>
        <div class="info">
            <h4>Barack Obama</h4>
            489 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Ben_Stiller.jpg" title="Click to see a thumbnail all images of Ben Stiller"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Ben_Stiller_thumb.jpg"></a>
        <div class="info">
            <h4>Ben Stiller</h4>
            117 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="Christina_Ricci.jpg" title="Click to see a thumbnail all images of Christina Ricci"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Christina_Ricci_thumb.jpg"></a>
        <div class="info">
            <h4>Christina Ricci</h4>
            312 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Clive_Owen.jpg" title="Click to see a thumbnail all images of Clive Owen"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Clive_Owen_thumb.jpg"></a>
        <div class="info">
            <h4>Clive Owen</h4>
            260 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Cristiano_Ronaldo.jpg" title="Click to see a thumbnail all images of Cristiano Ronaldo"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Cristiano_Ronaldo_thumb.jpg"></a>
        <div class="info">
            <h4>Cristiano Ronaldo</h4>
            281 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="Daniel_Craig.jpg" title="Click to see a thumbnail all images of Daniel Craig"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Daniel_Craig_thumb.jpg"></a>
        <div class="info">
            <h4>Daniel Craig</h4>
            403 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Danny_Devito.jpg" title="Click to see a thumbnail all images of Danny Devito"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Danny_Devito_thumb.jpg"></a>
        <div class="info">
            <h4>Danny Devito</h4>
            113 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/David_Duchovny.jpg" title="Click to see a thumbnail all images of David Duchovny"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/David_Duchovny_thumb.jpg"></a>
        <div class="info">
            <h4>David Duchovny</h4>
            405 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Denise_Richards.jpg" title="Click to see a thumbnail all images of Denise Richards"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Denise_Richards_thumb.jpg"></a>
        <div class="info">
            <h4>Denise Richards</h4>
            381 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Diane_Sawyer.jpg" title="Click to see a thumbnail all images of Diane Sawyer"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Diane_Sawyer_thumb.jpg"></a>
        <div class="info">
            <h4>Diane Sawyer</h4>
            79 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Donald_Faison.jpg" title="Click to see a thumbnail all images of Donald Faison"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Donald_Faison_thumb.jpg"></a>
        <div class="info">
            <h4>Donald Faison</h4>
            90 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Ehud_Olmert.jpg" title="Click to see a thumbnail all images of Ehud Olmert"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Ehud_Olmert_thumb.jpg"></a>
        <div class="info">
            <h4>Ehud Olmert</h4>
            230 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Faith_Hill.jpg" title="Click to see a thumbnail all images of Faith Hill"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Faith_Hill_thumb.jpg"></a>
        <div class="info">
            <h4>Faith Hill</h4>
            187 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Famke_Janssen.jpg" title="Click to see a thumbnail all images of Famke Janssen"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Famke_Janssen_thumb.jpg"></a>
        <div class="info">
            <h4>Famke Janssen</h4>
            122 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Hugh_Jackman.jpg" title="Click to see a thumbnail all images of Hugh Jackman"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Hugh_Jackman_thumb.jpg"></a>
        <div class="info">
            <h4>Hugh Jackman</h4>
            344 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Hugh_Laurie.jpg" title="Click to see a thumbnail all images of Hugh Laurie"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Hugh_Laurie_thumb.jpg"></a>
        <div class="info">
            <h4>Hugh Laurie</h4>
            346 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/James_Spader.jpg" title="Click to see a thumbnail all images of James Spader"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/James_Spader_thumb.jpg"></a>
        <div class="info">
            <h4>James Spader</h4>
            109 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jared_Leto.jpg" title="Click to see a thumbnail all images of Jared Leto"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jared_Leto_thumb.jpg"></a>
        <div class="info">
            <h4>Jared Leto</h4>
            260 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Julia_Roberts.jpg" title="Click to see a thumbnail all images of Julia Roberts"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Julia_Roberts_thumb.jpg"></a>
        <div class="info">
            <h4>Julia Roberts</h4>
            398 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Julia_Stiles.jpg" title="Click to see a thumbnail all images of Julia Stiles"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Julia_Stiles_thumb.jpg"></a>
        <div class="info">
            <h4>Julia Stiles</h4>
            228 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Karl_Rove.jpg" title="Click to see a thumbnail all images of Karl Rove"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Karl_Rove_thumb.jpg"></a>
        <div class="info">
            <h4>Karl Rove</h4>
            191 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Katherine_Heigl.jpg" title="Click to see a thumbnail all images of Katherine Heigl"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Katherine_Heigl_thumb.jpg"></a>
        <div class="info">
            <h4>Katherine Heigl</h4>
            489 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Kevin_Bacon.jpg" title="Click to see a thumbnail all images of Kevin Bacon"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Kevin_Bacon_thumb.jpg"></a>
        <div class="info">
            <h4>Kevin Bacon</h4>
            206 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Kiefer_Sutherland.jpg" title="Click to see a thumbnail all images of Kiefer Sutherland"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Kiefer_Sutherland_thumb.jpg"></a>
        <div class="info">
            <h4>Kiefer Sutherland</h4>
            334 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Kim_Basinger.jpg" title="Click to see a thumbnail all images of Kim Basinger"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Kim_Basinger_thumb.jpg"></a>
        <div class="info">
            <h4>Kim Basinger</h4>
            200 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Mark_Ruffalo.jpg" title="Click to see a thumbnail all images of Mark Ruffalo"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Mark_Ruffalo_thumb.jpg"></a>
        <div class="info">
            <h4>Mark Ruffalo</h4>
            167 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Meg_Ryan.jpg" title="Click to see a thumbnail all images of Meg Ryan"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Meg_Ryan_thumb.jpg"></a>
        <div class="info">
            <h4>Meg Ryan</h4>
            388 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Michelle_Trachtenberg.jpg" title="Click to see a thumbnail all images of Michelle Trachtenberg"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Michelle_Trachtenberg_thumb.jpg"></a>
        <div class="info">
            <h4>Michelle Trachtenberg</h4>
            325 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Michelle_Wie.jpg" title="Click to see a thumbnail all images of Michelle Wie"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Michelle_Wie_thumb.jpg"></a>
        <div class="info">
            <h4>Michelle Wie</h4>
            76 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Mickey_Rourke.jpg" title="Click to see a thumbnail all images of Mickey Rourke"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Mickey_Rourke_thumb.jpg"></a>
        <div class="info">
            <h4>Mickey Rourke</h4>
            296 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Miley_Cyrus.jpg" title="Click to see a thumbnail all images of Miley Cyrus"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Miley_Cyrus_thumb.jpg"></a>
        <div class="info">
            <h4>Miley Cyrus</h4>
            1895 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Milla_Jovovich.jpg" title="Click to see a thumbnail all images of Milla Jovovich"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Milla_Jovovich_thumb.jpg"></a>
        <div class="info">
            <h4>Milla Jovovich</h4>
            138 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Nicole_Richie.jpg" title="Click to see a thumbnail all images of Nicole Richie"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Nicole_Richie_thumb.jpg"></a>
        <div class="info">
            <h4>Nicole Richie</h4>
            332 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Rachael_Ray.jpg" title="Click to see a thumbnail all images of Rachael Ray"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Rachael_Ray_thumb.jpg"></a>
        <div class="info">
            <h4>Rachael Ray</h4>
            229 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Robert_Gates.jpg" title="Click to see a thumbnail all images of Robert Gates"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Robert_Gates_thumb.jpg"></a>
        <div class="info">
            <h4>Robert Gates</h4>
            206 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Ryan_Seacrest.jpg" title="Click to see a thumbnail all images of Ryan Seacrest"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Ryan_Seacrest_thumb.jpg"></a>
        <div class="info">
            <h4>Ryan Seacrest</h4>
            185 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Sania_Mirza.jpg" title="Click to see a thumbnail all images of Sania Mirza"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Sania_Mirza_thumb.jpg"></a>
        <div class="info">
            <h4>Sania Mirza</h4>
            275 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Sarah_Chalke.jpg" title="Click to see a thumbnail all images of Sarah Chalke"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Sarah_Chalke_thumb.jpg"></a>
        <div class="info">
            <h4>Sarah Chalke</h4>
            140 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Sarah_Palin.jpg" title="Click to see a thumbnail all images of Sarah Palin"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Sarah_Palin_thumb.jpg"></a>
        <div class="info">
            <h4>Sarah Palin</h4>
            122 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Scarlett_Johansson.jpg" title="Click to see a thumbnail all images of Scarlett Johansson"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Scarlett_Johansson_thumb.jpg"></a>
        <div class="info">
            <h4>Scarlett Johansson</h4>
            558 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Seth_Rogen.jpg" title="Click to see a thumbnail all images of Seth Rogen"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Seth_Rogen_thumb.jpg"></a>
        <div class="info">
            <h4>Seth Rogen</h4>
            223 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Shahrukh_Khan.jpg" title="Click to see a thumbnail all images of Shahrukh Khan"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Shahrukh_Khan_thumb.jpg"></a>
        <div class="info">
            <h4>Shahrukh Khan</h4>
            333 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Shakira.jpg" title="Click to see a thumbnail all images of Shakira"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Shakira_thumb.jpg"></a>
        <div class="info">
            <h4>Shakira</h4>
            505 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Stephen_Colbert.jpg" title="Click to see a thumbnail all images of Stephen Colbert"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Stephen_Colbert_thumb.jpg"></a>
        <div class="info">
            <h4>Stephen Colbert</h4>
            252 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Stephen_Fry.jpg" title="Click to see a thumbnail all images of Stephen Fry"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Stephen_Fry_thumb.jpg"></a>
        <div class="info">
            <h4>Stephen Fry</h4>
            97 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Steve_Carell.jpg" title="Click to see a thumbnail all images of Steve Carell"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Steve_Carell_thumb.jpg"></a>
        <div class="info">
            <h4>Steve Carell</h4>
            310 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Steve_Martin.jpg" title="Click to see a thumbnail all images of Steve Martin"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Steve_Martin_thumb.jpg"></a>
        <div class="info">
            <h4>Steve Martin</h4>
            170 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Tracy_Morgan.jpg" title="Click to see a thumbnail all images of Tracy Morgan"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Tracy_Morgan_thumb.jpg"></a>
        <div class="info">
            <h4>Tracy Morgan</h4>
            102 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Ty_Pennington.jpg" title="Click to see a thumbnail all images of Ty Pennington"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Ty_Pennington_thumb.jpg"></a>
        <div class="info">
            <h4>Ty Pennington</h4>
            116 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Viggo_Mortensen.jpg" title="Click to see a thumbnail all images of Viggo Mortensen"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Viggo_Mortensen_thumb.jpg"></a>
        <div class="info">
            <h4>Viggo Mortensen</h4>
            272 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Wilmer_Valderrama.jpg" title="Click to see a thumbnail all images of Wilmer Valderrama"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Wilmer_Valderrama_thumb.jpg"></a>
        <div class="info">
            <h4>Wilmer Valderrama</h4>
            105 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Zac_Efron.jpg" title="Click to see a thumbnail all images of Zac Efron"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Zac_Efron_thumb.jpg"></a>
        <div class="info">
            <h4>Zac Efron</h4>
            469 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Zach_Braff.jpg" title="Click to see a thumbnail all images of Zach Braff"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Zach_Braff_thumb.jpg"></a>
        <div class="info">
            <h4>Zach Braff</h4>
            203 Images
        </div>
    </div>
</div>
<div class="exploretable" id="evalpeople">
    <h2>Evaluation Set (140 People)</h2>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Aaron_Eckhart.jpg" title="Click to see a thumbnail all images of Aaron Eckhart"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Aaron_Eckhart_thumb.jpg"></a>
        <div class="info">
            <h4>Aaron Eckhart</h4>
            285 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Adam_Sandler.jpg" title="Click to see a thumbnail all images of Adam Sandler"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Adam_Sandler_thumb.jpg"></a>
        <div class="info">
            <h4>Adam Sandler</h4>
            306 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Adriana_Lima.jpg" title="Click to see a thumbnail all images of Adriana Lima"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Adriana_Lima_thumb.jpg"></a>
        <div class="info">
            <h4>Adriana Lima</h4>
            272 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Alberto_Gonzales.jpg" title="Click to see a thumbnail all images of Alberto Gonzales"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Alberto_Gonzales_thumb.jpg"></a>
        <div class="info">
            <h4>Alberto Gonzales</h4>
            227 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Alec_Baldwin.jpg" title="Click to see a thumbnail all images of Alec Baldwin"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Alec_Baldwin_thumb.jpg"></a>
        <div class="info">
            <h4>Alec Baldwin</h4>
            282 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Alicia_Keys.jpg" title="Click to see a thumbnail all images of Alicia Keys"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Alicia_Keys_thumb.jpg"></a>
        <div class="info">
            <h4>Alicia Keys</h4>
            395 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Angela_Merkel.jpg" title="Click to see a thumbnail all images of Angela Merkel"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Angela_Merkel_thumb.jpg"></a>
        <div class="info">
            <h4>Angela Merkel</h4>
            169 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Angelina_Jolie.jpg" title="Click to see a thumbnail all images of Angelina Jolie"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Angelina_Jolie_thumb.jpg"></a>
        <div class="info">
            <h4>Angelina Jolie</h4>
            1068 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Anna_Kournikova.jpg" title="Click to see a thumbnail all images of Anna Kournikova"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Anna_Kournikova_thumb.jpg"></a>
        <div class="info">
            <h4>Anna Kournikova</h4>
            318 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Antonio_Banderas.jpg" title="Click to see a thumbnail all images of Antonio Banderas"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Antonio_Banderas_thumb.jpg"></a>
        <div class="info">
            <h4>Antonio Banderas</h4>
            202 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Ashley_Judd.jpg" title="Click to see a thumbnail all images of Ashley Judd"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Ashley_Judd_thumb.jpg"></a>
        <div class="info">
            <h4>Ashley Judd</h4>
            228 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Ashton_Kutcher.jpg" title="Click to see a thumbnail all images of Ashton Kutcher"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Ashton_Kutcher_thumb.jpg"></a>
        <div class="info">
            <h4>Ashton Kutcher</h4>
            307 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Avril_Lavigne.jpg" title="Click to see a thumbnail all images of Avril Lavigne"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Avril_Lavigne_thumb.jpg"></a>
        <div class="info">
            <h4>Avril Lavigne</h4>
            767 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Ben_Affleck.jpg" title="Click to see a thumbnail all images of Ben Affleck"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Ben_Affleck_thumb.jpg"></a>
        <div class="info">
            <h4>Ben Affleck</h4>
            309 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Beyonce_Knowles.jpg" title="Click to see a thumbnail all images of Beyonce Knowles"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Beyonce_Knowles_thumb.jpg"></a>
        <div class="info">
            <h4>Beyonce Knowles</h4>
            441 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Bill_Clinton.jpg" title="Click to see a thumbnail all images of Bill Clinton"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Bill_Clinton_thumb.jpg"></a>
        <div class="info">
            <h4>Bill Clinton</h4>
            142 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Billy_Crystal.jpg" title="Click to see a thumbnail all images of Billy Crystal"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Billy_Crystal_thumb.jpg"></a>
        <div class="info">
            <h4>Billy Crystal</h4>
            114 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Bob_Dole.jpg" title="Click to see a thumbnail all images of Bob Dole"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Bob_Dole_thumb.jpg"></a>
        <div class="info">
            <h4>Bob Dole</h4>
            64 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Brad_Pitt.jpg" title="Click to see a thumbnail all images of Brad Pitt"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Brad_Pitt_thumb.jpg"></a>
        <div class="info">
            <h4>Brad Pitt</h4>
            1055 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Brendan_Fraser.jpg" title="Click to see a thumbnail all images of Brendan Fraser"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Brendan_Fraser_thumb.jpg"></a>
        <div class="info">
            <h4>Brendan Fraser</h4>
            216 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Bruce_Willis.jpg" title="Click to see a thumbnail all images of Bruce Willis"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Bruce_Willis_thumb.jpg"></a>
        <div class="info">
            <h4>Bruce Willis</h4>
            273 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Cameron_Diaz.jpg" title="Click to see a thumbnail all images of Cameron Diaz"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Cameron_Diaz_thumb.jpg"></a>
        <div class="info">
            <h4>Cameron Diaz</h4>
            480 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Carla_Gugino.jpg" title="Click to see a thumbnail all images of Carla Gugino"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Carla_Gugino_thumb.jpg"></a>
        <div class="info">
            <h4>Carla Gugino</h4>
            156 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Carson_Daly.jpg" title="Click to see a thumbnail all images of Carson Daly"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Carson_Daly_thumb.jpg"></a>
        <div class="info">
            <h4>Carson Daly</h4>
            84 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Cate_Blanchett.jpg" title="Click to see a thumbnail all images of Cate Blanchett"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Cate_Blanchett_thumb.jpg"></a>
        <div class="info">
            <h4>Cate Blanchett</h4>
            413 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Celine_Dion.jpg" title="Click to see a thumbnail all images of Celine Dion"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Celine_Dion_thumb.jpg"></a>
        <div class="info">
            <h4>Celine Dion</h4>
            233 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Charlize_Theron.jpg" title="Click to see a thumbnail all images of Charlize Theron"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Charlize_Theron_thumb.jpg"></a>
        <div class="info">
            <h4>Charlize Theron</h4>
            526 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Chris_Martin.jpg" title="Click to see a thumbnail all images of Chris Martin"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Chris_Martin_thumb.jpg"></a>
        <div class="info">
            <h4>Chris Martin</h4>
            76 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Christopher_Walken.jpg" title="Click to see a thumbnail all images of Christopher Walken"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Christopher_Walken_thumb.jpg"></a>
        <div class="info">
            <h4>Christopher Walken</h4>
            126 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Cindy_Crawford.jpg" title="Click to see a thumbnail all images of Cindy Crawford"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Cindy_Crawford_thumb.jpg"></a>
        <div class="info">
            <h4>Cindy Crawford</h4>
            177 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Claudia_Schiffer.jpg" title="Click to see a thumbnail all images of Claudia Schiffer"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Claudia_Schiffer_thumb.jpg"></a>
        <div class="info">
            <h4>Claudia Schiffer</h4>
            181 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Colin_Farrell.jpg" title="Click to see a thumbnail all images of Colin Farrell"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Colin_Farrell_thumb.jpg"></a>
        <div class="info">
            <h4>Colin Farrell</h4>
            422 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Colin_Powell.jpg" title="Click to see a thumbnail all images of Colin Powell"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Colin_Powell_thumb.jpg"></a>
        <div class="info">
            <h4>Colin Powell</h4>
            349 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Daisy_Fuentes.jpg" title="Click to see a thumbnail all images of Daisy Fuentes"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Daisy_Fuentes_thumb.jpg"></a>
        <div class="info">
            <h4>Daisy Fuentes</h4>
            78 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Daniel_Radcliffe.jpg" title="Click to see a thumbnail all images of Daniel Radcliffe"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Daniel_Radcliffe_thumb.jpg"></a>
        <div class="info">
            <h4>Daniel Radcliffe</h4>
            875 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Dave_Chappelle.jpg" title="Click to see a thumbnail all images of Dave Chappelle"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Dave_Chappelle_thumb.jpg"></a>
        <div class="info">
            <h4>Dave Chappelle</h4>
            63 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/David_Beckham.jpg" title="Click to see a thumbnail all images of David Beckham"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/David_Beckham_thumb.jpg"></a>
        <div class="info">
            <h4>David Beckham</h4>
            367 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Denzel_Washington.jpg" title="Click to see a thumbnail all images of Denzel Washington"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Denzel_Washington_thumb.jpg"></a>
        <div class="info">
            <h4>Denzel Washington</h4>
            229 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Donald_Trump.jpg" title="Click to see a thumbnail all images of Donald Trump"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Donald_Trump_thumb.jpg"></a>
        <div class="info">
            <h4>Donald Trump</h4>
            247 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Drew_Barrymore.jpg" title="Click to see a thumbnail all images of Drew Barrymore"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Drew_Barrymore_thumb.jpg"></a>
        <div class="info">
            <h4>Drew Barrymore</h4>
            443 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Dustin_Hoffman.jpg" title="Click to see a thumbnail all images of Dustin Hoffman"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Dustin_Hoffman_thumb.jpg"></a>
        <div class="info">
            <h4>Dustin Hoffman</h4>
            217 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Edie_Falco.jpg" title="Click to see a thumbnail all images of Edie Falco"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Edie_Falco_thumb.jpg"></a>
        <div class="info">
            <h4>Edie Falco</h4>
            111 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Eliot_Spitzer.jpg" title="Click to see a thumbnail all images of Eliot Spitzer"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Eliot_Spitzer_thumb.jpg"></a>
        <div class="info">
            <h4>Eliot Spitzer</h4>
            232 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Eliza_Dushku.jpg" title="Click to see a thumbnail all images of Eliza Dushku"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Eliza_Dushku_thumb.jpg"></a>
        <div class="info">
            <h4>Eliza Dushku</h4>
            258 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Eva_Mendes.jpg" title="Click to see a thumbnail all images of Eva Mendes"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Eva_Mendes_thumb.jpg"></a>
        <div class="info">
            <h4>Eva Mendes</h4>
            468 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Gael_Garcia_Bernal.jpg" title="Click to see a thumbnail all images of Gael Garcia Bernal"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Gael_Garcia_Bernal_thumb.jpg"></a>
        <div class="info">
            <h4>Gael Garcia Bernal</h4>
            268 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Gene_Hackman.jpg" title="Click to see a thumbnail all images of Gene Hackman"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Gene_Hackman_thumb.jpg"></a>
        <div class="info">
            <h4>Gene Hackman</h4>
            92 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/George_Clooney.jpg" title="Click to see a thumbnail all images of George Clooney"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/George_Clooney_thumb.jpg"></a>
        <div class="info">
            <h4>George Clooney</h4>
            540 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/George_W_Bush.jpg" title="Click to see a thumbnail all images of George W Bush"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/George_W_Bush_thumb.jpg"></a>
        <div class="info">
            <h4>George W Bush</h4>
            162 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Gillian_Anderson.jpg" title="Click to see a thumbnail all images of Gillian Anderson"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Gillian_Anderson_thumb.jpg"></a>
        <div class="info">
            <h4>Gillian Anderson</h4>
            266 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Gisele_Bundchen.jpg" title="Click to see a thumbnail all images of Gisele Bundchen"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Gisele_Bundchen_thumb.jpg"></a>
        <div class="info">
            <h4>Gisele Bundchen</h4>
            194 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Gordon_Brown.jpg" title="Click to see a thumbnail all images of Gordon Brown"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Gordon_Brown_thumb.jpg"></a>
        <div class="info">
            <h4>Gordon Brown</h4>
            148 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Gwyneth_Paltrow.jpg" title="Click to see a thumbnail all images of Gwyneth Paltrow"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Gwyneth_Paltrow_thumb.jpg"></a>
        <div class="info">
            <h4>Gwyneth Paltrow</h4>
            517 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Halle_Berry.jpg" title="Click to see a thumbnail all images of Halle Berry"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Halle_Berry_thumb.jpg"></a>
        <div class="info">
            <h4>Halle Berry</h4>
            486 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Harrison_Ford.jpg" title="Click to see a thumbnail all images of Harrison Ford"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Harrison_Ford_thumb.jpg"></a>
        <div class="info">
            <h4>Harrison Ford</h4>
            340 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Holly_Hunter.jpg" title="Click to see a thumbnail all images of Holly Hunter"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Holly_Hunter_thumb.jpg"></a>
        <div class="info">
            <h4>Holly Hunter</h4>
            71 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Hugh_Grant.jpg" title="Click to see a thumbnail all images of Hugh Grant"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Hugh_Grant_thumb.jpg"></a>
        <div class="info">
            <h4>Hugh Grant</h4>
            335 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jack_Nicholson.jpg" title="Click to see a thumbnail all images of Jack Nicholson"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jack_Nicholson_thumb.jpg"></a>
        <div class="info">
            <h4>Jack Nicholson</h4>
            206 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/James_Franco.jpg" title="Click to see a thumbnail all images of James Franco"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/James_Franco_thumb.jpg"></a>
        <div class="info">
            <h4>James Franco</h4>
            296 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/James_Gandolfini.jpg" title="Click to see a thumbnail all images of James Gandolfini"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/James_Gandolfini_thumb.jpg"></a>
        <div class="info">
            <h4>James Gandolfini</h4>
            192 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jason_Statham.jpg" title="Click to see a thumbnail all images of Jason Statham"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jason_Statham_thumb.jpg"></a>
        <div class="info">
            <h4>Jason Statham</h4>
            187 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Javier_Bardem.jpg" title="Click to see a thumbnail all images of Javier Bardem"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Javier_Bardem_thumb.jpg"></a>
        <div class="info">
            <h4>Javier Bardem</h4>
            157 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jay_Leno.jpg" title="Click to see a thumbnail all images of Jay Leno"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jay_Leno_thumb.jpg"></a>
        <div class="info">
            <h4>Jay Leno</h4>
            193 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jeff_Bridges.jpg" title="Click to see a thumbnail all images of Jeff Bridges"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jeff_Bridges_thumb.jpg"></a>
        <div class="info">
            <h4>Jeff Bridges</h4>
            72 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jennifer_Aniston.jpg" title="Click to see a thumbnail all images of Jennifer Aniston"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jennifer_Aniston_thumb.jpg"></a>
        <div class="info">
            <h4>Jennifer Aniston</h4>
            612 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jennifer_Lopez.jpg" title="Click to see a thumbnail all images of Jennifer Lopez"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jennifer_Lopez_thumb.jpg"></a>
        <div class="info">
            <h4>Jennifer Lopez</h4>
            476 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jennifer_Love_Hewitt.jpg" title="Click to see a thumbnail all images of Jennifer Love Hewitt"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jennifer_Love_Hewitt_thumb.jpg"></a>
        <div class="info">
            <h4>Jennifer Love Hewitt</h4>
            371 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jeri_Ryan.jpg" title="Click to see a thumbnail all images of Jeri Ryan"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jeri_Ryan_thumb.jpg"></a>
        <div class="info">
            <h4>Jeri Ryan</h4>
            153 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jerry_Seinfeld.jpg" title="Click to see a thumbnail all images of Jerry Seinfeld"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jerry_Seinfeld_thumb.jpg"></a>
        <div class="info">
            <h4>Jerry Seinfeld</h4>
            178 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jessica_Alba.jpg" title="Click to see a thumbnail all images of Jessica Alba"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jessica_Alba_thumb.jpg"></a>
        <div class="info">
            <h4>Jessica Alba</h4>
            561 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jessica_Simpson.jpg" title="Click to see a thumbnail all images of Jessica Simpson"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jessica_Simpson_thumb.jpg"></a>
        <div class="info">
            <h4>Jessica Simpson</h4>
            542 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jimmy_Carter.jpg" title="Click to see a thumbnail all images of Jimmy Carter"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jimmy_Carter_thumb.jpg"></a>
        <div class="info">
            <h4>Jimmy Carter</h4>
            160 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Joaquin_Phoenix.jpg" title="Click to see a thumbnail all images of Joaquin Phoenix"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Joaquin_Phoenix_thumb.jpg"></a>
        <div class="info">
            <h4>Joaquin Phoenix</h4>
            262 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jodie_Foster.jpg" title="Click to see a thumbnail all images of Jodie Foster"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jodie_Foster_thumb.jpg"></a>
        <div class="info">
            <h4>Jodie Foster</h4>
            367 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/John_Lennon.jpg" title="Click to see a thumbnail all images of John Lennon"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/John_Lennon_thumb.jpg"></a>
        <div class="info">
            <h4>John Lennon</h4>
            153 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/John_Malkovich.jpg" title="Click to see a thumbnail all images of John Malkovich"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/John_Malkovich_thumb.jpg"></a>
        <div class="info">
            <h4>John Malkovich</h4>
            117 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/John_Travolta.jpg" title="Click to see a thumbnail all images of John Travolta"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/John_Travolta_thumb.jpg"></a>
        <div class="info">
            <h4>John Travolta</h4>
            371 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jon_Stewart.jpg" title="Click to see a thumbnail all images of Jon Stewart"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Jon_Stewart_thumb.jpg"></a>
        <div class="info">
            <h4>Jon Stewart</h4>
            170 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Kate_Moss.jpg" title="Click to see a thumbnail all images of Kate Moss"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Kate_Moss_thumb.jpg"></a>
        <div class="info">
            <h4>Kate Moss</h4>
            299 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Kate_Winslet.jpg" title="Click to see a thumbnail all images of Kate Winslet"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Kate_Winslet_thumb.jpg"></a>
        <div class="info">
            <h4>Kate Winslet</h4>
            343 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Katie_Couric.jpg" title="Click to see a thumbnail all images of Katie Couric"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Katie_Couric_thumb.jpg"></a>
        <div class="info">
            <h4>Katie Couric</h4>
            228 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Keanu_Reeves.jpg" title="Click to see a thumbnail all images of Keanu Reeves"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Keanu_Reeves_thumb.jpg"></a>
        <div class="info">
            <h4>Keanu Reeves</h4>
            305 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Keira_Knightley.jpg" title="Click to see a thumbnail all images of Keira Knightley"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Keira_Knightley_thumb.jpg"></a>
        <div class="info">
            <h4>Keira Knightley</h4>
            560 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Lance_Armstrong.jpg" title="Click to see a thumbnail all images of Lance Armstrong"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Lance_Armstrong_thumb.jpg"></a>
        <div class="info">
            <h4>Lance Armstrong</h4>
            186 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Leonardo_DiCaprio.jpg" title="Click to see a thumbnail all images of Leonardo DiCaprio"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Leonardo_DiCaprio_thumb.jpg"></a>
        <div class="info">
            <h4>Leonardo DiCaprio</h4>
            658 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Liam_Neeson.jpg" title="Click to see a thumbnail all images of Liam Neeson"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Liam_Neeson_thumb.jpg"></a>
        <div class="info">
            <h4>Liam Neeson</h4>
            177 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Lindsay_Lohan.jpg" title="Click to see a thumbnail all images of Lindsay Lohan"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Lindsay_Lohan_thumb.jpg"></a>
        <div class="info">
            <h4>Lindsay Lohan</h4>
            1505 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Liv_Tyler.jpg" title="Click to see a thumbnail all images of Liv Tyler"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Liv_Tyler_thumb.jpg"></a>
        <div class="info">
            <h4>Liv Tyler</h4>
            292 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Lucy_Liu.jpg" title="Click to see a thumbnail all images of Lucy Liu"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Lucy_Liu_thumb.jpg"></a>
        <div class="info">
            <h4>Lucy Liu</h4>
            301 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Mariah_Carey.jpg" title="Click to see a thumbnail all images of Mariah Carey"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Mariah_Carey_thumb.jpg"></a>
        <div class="info">
            <h4>Mariah Carey</h4>
            332 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Martha_Stewart.jpg" title="Click to see a thumbnail all images of Martha Stewart"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Martha_Stewart_thumb.jpg"></a>
        <div class="info">
            <h4>Martha Stewart</h4>
            245 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Matt_Damon.jpg" title="Click to see a thumbnail all images of Matt Damon"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Matt_Damon_thumb.jpg"></a>
        <div class="info">
            <h4>Matt Damon</h4>
            397 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Matthew_Broderick.jpg" title="Click to see a thumbnail all images of Matthew Broderick"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Matthew_Broderick_thumb.jpg"></a>
        <div class="info">
            <h4>Matthew Broderick</h4>
            150 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Mel_Gibson.jpg" title="Click to see a thumbnail all images of Mel Gibson"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Mel_Gibson_thumb.jpg"></a>
        <div class="info">
            <h4>Mel Gibson</h4>
            391 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Meryl_Streep.jpg" title="Click to see a thumbnail all images of Meryl Streep"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Meryl_Streep_thumb.jpg"></a>
        <div class="info">
            <h4>Meryl Streep</h4>
            352 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Michael_Bloomberg.jpg" title="Click to see a thumbnail all images of Michael Bloomberg"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Michael_Bloomberg_thumb.jpg"></a>
        <div class="info">
            <h4>Michael Bloomberg</h4>
            249 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Michael_Douglas.jpg" title="Click to see a thumbnail all images of Michael Douglas"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Michael_Douglas_thumb.jpg"></a>
        <div class="info">
            <h4>Michael Douglas</h4>
            192 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Mikhail_Gorbachev.jpg" title="Click to see a thumbnail all images of Mikhail Gorbachev"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Mikhail_Gorbachev_thumb.jpg"></a>
        <div class="info">
            <h4>Mikhail Gorbachev</h4>
            130 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Minnie_Driver.jpg" title="Click to see a thumbnail all images of Minnie Driver"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Minnie_Driver_thumb.jpg"></a>
        <div class="info">
            <h4>Minnie Driver</h4>
            150 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Monica_Bellucci.jpg" title="Click to see a thumbnail all images of Monica Bellucci"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Monica_Bellucci_thumb.jpg"></a>
        <div class="info">
            <h4>Monica Bellucci</h4>
            250 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Morgan_Freeman.jpg" title="Click to see a thumbnail all images of Morgan Freeman"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Morgan_Freeman_thumb.jpg"></a>
        <div class="info">
            <h4>Morgan Freeman</h4>
            306 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Nathan_Lane.jpg" title="Click to see a thumbnail all images of Nathan Lane"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Nathan_Lane_thumb.jpg"></a>
        <div class="info">
            <h4>Nathan Lane</h4>
            69 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Nicolas_Cage.jpg" title="Click to see a thumbnail all images of Nicolas Cage"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Nicolas_Cage_thumb.jpg"></a>
        <div class="info">
            <h4>Nicolas Cage</h4>
            318 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Nicolas_Sarkozy.jpg" title="Click to see a thumbnail all images of Nicolas Sarkozy"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Nicolas_Sarkozy_thumb.jpg"></a>
        <div class="info">
            <h4>Nicolas Sarkozy</h4>
            125 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Nicole_Kidman.jpg" title="Click to see a thumbnail all images of Nicole Kidman"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Nicole_Kidman_thumb.jpg"></a>
        <div class="info">
            <h4>Nicole Kidman</h4>
            613 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Noah_Wyle.jpg" title="Click to see a thumbnail all images of Noah Wyle"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Noah_Wyle_thumb.jpg"></a>
        <div class="info">
            <h4>Noah Wyle</h4>
            106 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Oprah_Winfrey.jpg" title="Click to see a thumbnail all images of Oprah Winfrey"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Oprah_Winfrey_thumb.jpg"></a>
        <div class="info">
            <h4>Oprah Winfrey</h4>
            444 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Orlando_Bloom.jpg" title="Click to see a thumbnail all images of Orlando Bloom"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Orlando_Bloom_thumb.jpg"></a>
        <div class="info">
            <h4>Orlando Bloom</h4>
            1231 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Owen_Wilson.jpg" title="Click to see a thumbnail all images of Owen Wilson"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Owen_Wilson_thumb.jpg"></a>
        <div class="info">
            <h4>Owen Wilson</h4>
            177 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Philip_Seymour_Hoffman.jpg" title="Click to see a thumbnail all images of Philip Seymour Hoffman"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Philip_Seymour_Hoffman_thumb.jpg"></a>
        <div class="info">
            <h4>Philip Seymour Hoffman</h4>
            117 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Quincy_Jones.jpg" title="Click to see a thumbnail all images of Quincy Jones"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Quincy_Jones_thumb.jpg"></a>
        <div class="info">
            <h4>Quincy Jones</h4>
            125 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Ralph_Nader.jpg" title="Click to see a thumbnail all images of Ralph Nader"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Ralph_Nader_thumb.jpg"></a>
        <div class="info">
            <h4>Ralph Nader</h4>
            182 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Ray_Romano.jpg" title="Click to see a thumbnail all images of Ray Romano"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Ray_Romano_thumb.jpg"></a>
        <div class="info">
            <h4>Ray Romano</h4>
            68 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Reese_Witherspoon.jpg" title="Click to see a thumbnail all images of Reese Witherspoon"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Reese_Witherspoon_thumb.jpg"></a>
        <div class="info">
            <h4>Reese Witherspoon</h4>
            362 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Renee_Zellweger.jpg" title="Click to see a thumbnail all images of Renee Zellweger"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Renee_Zellweger_thumb.jpg"></a>
        <div class="info">
            <h4>Renee Zellweger</h4>
            342 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Ricky_Martin.jpg" title="Click to see a thumbnail all images of Ricky Martin"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Ricky_Martin_thumb.jpg"></a>
        <div class="info">
            <h4>Ricky Martin</h4>
            329 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Robert_Downey_Jr.jpg" title="Click to see a thumbnail all images of Robert Downey Jr"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Robert_Downey_Jr_thumb.jpg"></a>
        <div class="info">
            <h4>Robert Downey Jr</h4>
            130 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Rod_Stewart.jpg" title="Click to see a thumbnail all images of Rod Stewart"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Rod_Stewart_thumb.jpg"></a>
        <div class="info">
            <h4>Rod Stewart</h4>
            117 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Rosario_Dawson.jpg" title="Click to see a thumbnail all images of Rosario Dawson"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Rosario_Dawson_thumb.jpg"></a>
        <div class="info">
            <h4>Rosario Dawson</h4>
            327 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Rosie_Perez.jpg" title="Click to see a thumbnail all images of Rosie Perez"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Rosie_Perez_thumb.jpg"></a>
        <div class="info">
            <h4>Rosie Perez</h4>
            85 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Russell_Crowe.jpg" title="Click to see a thumbnail all images of Russell Crowe"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Russell_Crowe_thumb.jpg"></a>
        <div class="info">
            <h4>Russell Crowe</h4>
            297 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Salma_Hayek.jpg" title="Click to see a thumbnail all images of Salma Hayek"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Salma_Hayek_thumb.jpg"></a>
        <div class="info">
            <h4>Salma Hayek</h4>
            480 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Shania_Twain.jpg" title="Click to see a thumbnail all images of Shania Twain"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Shania_Twain_thumb.jpg"></a>
        <div class="info">
            <h4>Shania Twain</h4>
            231 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Sharon_Stone.jpg" title="Click to see a thumbnail all images of Sharon Stone"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Sharon_Stone_thumb.jpg"></a>
        <div class="info">
            <h4>Sharon Stone</h4>
            423 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Shinzo_Abe.jpg" title="Click to see a thumbnail all images of Shinzo Abe"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Shinzo_Abe_thumb.jpg"></a>
        <div class="info">
            <h4>Shinzo Abe</h4>
            71 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Sigourney_Weaver.jpg" title="Click to see a thumbnail all images of Sigourney Weaver"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Sigourney_Weaver_thumb.jpg"></a>
        <div class="info">
            <h4>Sigourney Weaver</h4>
            204 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Silvio_Berlusconi.jpg" title="Click to see a thumbnail all images of Silvio Berlusconi"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Silvio_Berlusconi_thumb.jpg"></a>
        <div class="info">
            <h4>Silvio Berlusconi</h4>
            238 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Simon_Cowell.jpg" title="Click to see a thumbnail all images of Simon Cowell"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Simon_Cowell_thumb.jpg"></a>
        <div class="info">
            <h4>Simon Cowell</h4>
            363 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Steven_Spielberg.jpg" title="Click to see a thumbnail all images of Steven Spielberg"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Steven_Spielberg_thumb.jpg"></a>
        <div class="info">
            <h4>Steven Spielberg</h4>
            237 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Susan_Sarandon.jpg" title="Click to see a thumbnail all images of Susan Sarandon"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Susan_Sarandon_thumb.jpg"></a>
        <div class="info">
            <h4>Susan Sarandon</h4>
            229 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Tiger_Woods.jpg" title="Click to see a thumbnail all images of Tiger Woods"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Tiger_Woods_thumb.jpg"></a>
        <div class="info">
            <h4>Tiger Woods</h4>
            169 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Tina_Fey.jpg" title="Click to see a thumbnail all images of Tina Fey"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Tina_Fey_thumb.jpg"></a>
        <div class="info">
            <h4>Tina Fey</h4>
            269 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Tom_Cruise.jpg" title="Click to see a thumbnail all images of Tom Cruise"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Tom_Cruise_thumb.jpg"></a>
        <div class="info">
            <h4>Tom Cruise</h4>
            514 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Tom_Hanks.jpg" title="Click to see a thumbnail all images of Tom Hanks"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Tom_Hanks_thumb.jpg"></a>
        <div class="info">
            <h4>Tom Hanks</h4>
            281 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Tony_Blair.jpg" title="Click to see a thumbnail all images of Tony Blair"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Tony_Blair_thumb.jpg"></a>
        <div class="info">
            <h4>Tony Blair</h4>
            170 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Tyra_Banks.jpg" title="Click to see a thumbnail all images of Tyra Banks"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Tyra_Banks_thumb.jpg"></a>
        <div class="info">
            <h4>Tyra Banks</h4>
            374 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Uma_Thurman.jpg" title="Click to see a thumbnail all images of Uma Thurman"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Uma_Thurman_thumb.jpg"></a>
        <div class="info">
            <h4>Uma Thurman</h4>
            357 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Victoria_Beckham.jpg" title="Click to see a thumbnail all images of Victoria Beckham"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Victoria_Beckham_thumb.jpg"></a>
        <div class="info">
            <h4>Victoria Beckham</h4>
            406 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Will_Smith.jpg" title="Click to see a thumbnail all images of Will Smith"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/Will_Smith_thumb.jpg"></a>
        <div class="info">
            <h4>Will Smith</h4>
            317 Images
        </div>
    </div>
    <div class="exploreperson">
        <a href="https://cave.cs.columbia.edu/old/databases/pubfig/explore/William_Macy.jpg" title="Click to see a thumbnail all images of William Macy"><img src="https://cave.cs.columbia.edu/old/databases/pubfig/explore/William_Macy_thumb.jpg"></a>
        <div class="info">
            <h4>William Macy</h4>
            100 Images
        </div>
    </div>
</div>
</div>

<div id="footer">
    (c) 2010 Columbia University | Contact <a href="mailto:neeraj@cs.columbia.edu">Neeraj Kumar</a> for any questions/problems/bug reports/etc. | Current PubFig version: <b>1.2</b>
</div>
<br>
</div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
#everything {
    font-family: Arial, sans-serif;
    padding: 10px;
    line-height: 1.3em;
    margin: 0 auto;
    width: 1000px;
    margin-top: 10px;
}

.teaser {
    display: block;
    margin: 0 auto;
}

a {
    color: blue;
    text-decoration: none;
}

#header {
    position: relative;
    display: block;
    height: 2.2em;
    /* border-bottom: 1px solid lightgray; */
    margin-bottom: 10px;
}

#header h2 {
    margin-top: 5px;
    display: inline-block;
    margin-left: 15px;
}

#header a {
    color: black;
}
#data{
    text-align: left;
}
#header a:hover {
    color: blue;
}

a:hover {
    text-decoration: underline;
}

#header .left {
    position: absolute;
    left: 0;
}

#header .left h2 {
    margin-left: 0;
}

#header .right {
    position: absolute;
    right: 0;
}

#header .selected {
    color: #ff872b;
}

h1 {
    font-size: 2.0em;
    margin-bottom: 10px;
}

h1.sectiontitle {
    text-align: center;
    margin: 20px;
}

h2 {
    font-size: 1.2em;
    margin: 20px 0 10px 0;
}

p {
    margin-bottom: 1em;
}

li {
    list-style: square outside;
    margin-left: 1em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

ol li {
    list-style-type: decimal;
    margin-left: 1.5em;
}

li li {
    list-style: circle outside;
}



div#content {
    clear: both;
    width: 100%;
    background: #f1ebda;
    padding-top: 10px;
    padding-bottom: 3em;
}

table caption {
    font-size: 2em;
}

h3 {
    margin-top: 5px;
    text-align: center;
}


div.resultpane {
    margin: 0 auto;
    margin-bottom: 30px;
}

div.rocdiv {
    margin-top: 2em;
    text-align: center;
}
div.tablediv {
    position: relative;
    text-align: center;
    margin-bottom: 1em;
}
table.resultstable {
    margin: 0 auto;
    border: 1px solid black;
}
table.resultstable th {
    border-bottom: 1px dotted black;
    padding: 5px;
    text-align: center;
}

table.resultstable td {
    padding: 5px;
    text-align: center;
}

.first{
    padding-right: 5px;
    border-right: 1px dotted black;
}

.exploretable {
    margin: 0 auto;
    text-align: center;
}


.exploreperson {
    display: inline-block;
    width: 186px;
    height: 130px;
    margin-bottom: 1em;
    margin-left: 5px;
    margin-right: 5px;
}

.exploreperson img {
    width: 170px;
    height: 85px;
    padding: 2px;
    border: 1px solid lightgray;
}

.exploreperson a:hover img {
    border-color: blue;
}

blockquote {
    margin-left: 2em;
    margin-top: 0.5em;
}

.fakelink {
    cursor: pointer;
}
h2{
    text-align: left;
}
.bibref {
    margin-top: 10px;
    margin-left: 10px;
    display:none;
    font-family: monospace;
}


.code {
    font-family: monospace;
    font-size: 1.2em;
}

pre {
    text-align: left;
    padding: 5px;
    background: lightgray;
    margin: 5px 0;
}

#footer {
    clear: both;
    text-align: center;
    border-top: 1px solid lightgray;
    padding-top: 5px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 0.9em;
}

</style>